import licit from '@mc-alberta/licit'
import { lumber } from '@mc-alberta/lumber'
import messengerLegacy from '@mc-alberta/messenger'
import messengerBeta from '@mc-alberta/messenger-beta'
import SRCError from '../SRCError'

import {
  buildDCFMessage,
  clearSDKState,
  findCard,
  getWindow,
  isMastercardDcf,
  updateActionsToComplyWithEMVCO,
  watchClose
} from '../helpers/checkout'
import normalizeCustomInputData from '../helpers/normalizeCustomInputData'
import normalizeDpaLocale from '../helpers/normalizeDpaLocale'
import transform from '../transform'
import helpers from '../transform/helpers'
import utils from '../utils'
import { CARD_BRANDS } from '../utils/constants'
import validate from '../validate'
import { DCF_ACTION, DCF_READY, TPW_DCF_ACTION, TPW_DCF_READY } from '../values'
import enrollCard from './enroll'

let actionDcfEvent

// eslint-disable-next-line complexity
export async function checkout({
  srcDigitalCardId, // conditional - required if we dont get encryptedCard
  encryptedCard, // conditional
  idToken, // optional
  dpaTransactionOptions, // optional
  assuranceData, // optional
  srciActionCode, // optional
  windowRef, // optional
  srciTransactionId, // optional,
  __ma_params: mcSdkCustomParams,
  consumer,
  complianceSettings, // optional
  payloadTypeIndicatorCheckout, // optional
  recipientIdCheckout, // optional
  appInstance // optional
}) {
  const { state } = this

  if (licit.isAnObject(dpaTransactionOptions)) {
    dpaTransactionOptions = normalizeDpaLocale(dpaTransactionOptions)
    dpaTransactionOptions = normalizeCustomInputData(dpaTransactionOptions, state)
    validate.params({ dpaTransactionOptions })
  }

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'checkout'
    },
    state
  )
  let dcfWindowRef
  let readyDcfEvent

  // if we get a card instead of cardid, first enroll it, then checkout with it
  async function selectCard() {
    if (encryptedCard) {
      // TODO: move enrollCard() param validation to before window.open
      // in enroll case we need to open window before API call.

      // we open window before enroll, because waiting for it loses
      // user click association
      dcfWindowRef = getWindow({ dcfWindowRef, windowRef })
      const response = await enrollCard.call(this, {
        encryptedCard,
        __ma_params: mcSdkCustomParams
      })
      state.maskedCard = response.maskedCard
      return response.maskedCard
    } else {
      validate.params({ srcDigitalCardId })
      const maskedCard = findCard.call(this, { srcDigitalCardId })
      state.maskedCard = maskedCard
      return maskedCard
    }
  }

  function hasCancelMethod(event) {
    return event && typeof event.cancel === 'function'
  }

  function closeOpenedPopupAndListeners({ dcfWindowRef, windowRef }) {
    // if we opened the window, close it
    if (!windowRef && dcfWindowRef) dcfWindowRef.close()

    // cancelling READY and ACTION event listeners on popup close
    if (hasCancelMethod(readyDcfEvent)) readyDcfEvent.cancel()
    if (hasCancelMethod(actionDcfEvent)) actionDcfEvent.cancel()
  }

  try {
    const transactionOptions = helpers.cloneDeep(
      dpaTransactionOptions || state.dpaTransactionOptions || {}
    )
    const acceptedCardBrands = transactionOptions?.customInputData?.[
      'com.mastercard.acceptedCardBrands'
    ] || [CARD_BRANDS.MASTERCARD]
    delete transactionOptions?.customInputData?.['com.mastercard.acceptedCardBrands']

    validate.transactionOptions({ transactionOptions })

    if (srciTransactionId) {
      state.headers = {
        ...state.headers,
        'SRCI-Transaction-Id': srciTransactionId
      }
    }

    const maskedCard = await selectCard.call(this)
    validate.params({ maskedCard })

    if (!acceptedCardBrands.includes(maskedCard?.paymentCardDescriptor)) {
      throw new SRCError({
        error: {
          status: 400,
          reason: 'INVALID_PARAMETER',
          message: `The enrolled card's brand, ${maskedCard?.paymentCardDescriptor}, is not accepted by the merchant.`
        }
      })
    }

    // dont open window until we know we have a card
    dcfWindowRef = getWindow({ dcfWindowRef, windowRef })

    // wait for the DCF to load and the send it all init data
    const baseUri = maskedCard.dcf.uri ? maskedCard.dcf.uri + `?traceId=${state.traceId}` : ''
    const url =
      process.env.NODE_ENV === 'development'
        ? baseUri.replace(/https:\/\/[a-z.]+\.mastercard.com\//, '/')
        : baseUri
    const origin =
      process.env.NODE_ENV === 'development' ? window.location.origin : utils.getOrigin(url)

    const { messenger, dcfReady, dcfAction } = isMastercardDcf(maskedCard)
      ? { messenger: messengerLegacy, dcfReady: DCF_READY, dcfAction: DCF_ACTION }
      : { messenger: messengerBeta, dcfReady: TPW_DCF_READY, dcfAction: TPW_DCF_ACTION }

    // TODO: set a timeout and if this doesnt 'READY' before then throw UNABLE_TO_CONNECT
    readyDcfEvent = messenger.once(dcfReady, { window: dcfWindowRef, domain: origin }, () => {
      return buildDCFMessage.call(this, {
        state,
        maskedCard,
        idToken,
        transactionOptions,
        assuranceData,
        srciActionCode,
        consumer,
        complianceSettings,
        payloadTypeIndicatorCheckout,
        recipientIdCheckout,
        appInstance
      })
    })
    dcfWindowRef.location.replace(url)

    // wait for the DCF to respond, or be closed
    const result = await Promise.race([
      getMessage({ dcfAction, dcfWindowRef, origin, messenger }),
      watchClose({ dcfWindowRef })
    ])

    // TODO: handle errors
    // CARD_EXP_INVALID
    // UNABLE_TO_CONNECT
    // AUTH_INVALID
    // TERMS_AND_CONDITIONS_NOT_ACCEPTED

    clearSDKState.call(this, result)
    closeOpenedPopupAndListeners({ dcfWindowRef, windowRef })
    updateActionsToComplyWithEMVCO(result)
    validate.checkoutResult(result)

    return transform.checkout.response(result)
  } catch (err) {
    closeOpenedPopupAndListeners({ dcfWindowRef, windowRef })
    throw err
  } finally {
    lumber.flushQueue()
  }
}

// wait for the DCF to send back a message about checkout success/cancel/change
async function getMessage({ dcfAction, dcfWindowRef, origin, messenger }) {
  actionDcfEvent = messenger.once(dcfAction, { window: dcfWindowRef, domain: origin })
  const result = await actionDcfEvent
  return result.data
}
