import licit from '@mc-alberta/licit'
import events from '../../events.json'
import { validAcceptedCardBrands, validDcfExperiences } from '../validations/rules/emvco/enums'
import remoteLogger from './remoteLogging'
import isValidAcceptedCardBrands from './validations/isValidAcceptedCardBrand'

export default function normalizeCustomInputData(dpaTransactionOptions, state) {
  if (
    !licit.isAnObject(dpaTransactionOptions) ||
    !licit.isAnObject(dpaTransactionOptions.customInputData)
  ) {
    return dpaTransactionOptions
  }
  let response = dpaTransactionOptions

  try {
    const clonedOptions = JSON.parse(JSON.stringify(dpaTransactionOptions))
    /* eslint-disable-next-line complexity */ // TODO: Reduce complexity
    Object.keys(clonedOptions.customInputData).forEach((key) => {
      if (key === 'com.mastercard.dcfExperience') {
        if (!validDcfExperiences.includes(clonedOptions.customInputData[key])) {
          console.warn(
            `[MC SDK] > value for dpaTransactionOptions.customInputData['${key}'] must be one of the following:`,
            validDcfExperiences
          )
          remoteLogger.warn(
            {
              ...events.warnings.INVALID_CUSTOM_INPUT_DATA,
              details: {
                message: `src-sdk received an invalid value for dpaTransactionOptions.customInputData['${key}']`
              }
            },
            state
          )
          delete clonedOptions.customInputData[key]
        }
      } else if (key === 'com.mastercard.acceptedCardBrands') {
        if (
          !licit.isAnArray(clonedOptions.customInputData[key]) ||
          !isValidAcceptedCardBrands(clonedOptions.customInputData[key])
        ) {
          console.warn(
            `[MC SDK] > value for dpaTransactionOptions.customInputData['${key}'] must be an array containing one or both of the following:`,
            validAcceptedCardBrands
          )
          remoteLogger.warn(
            {
              ...events.warnings.INVALID_CUSTOM_INPUT_DATA,
              details: {
                message: `src-sdk received an invalid value for dpaTransactionOptions.customInputData['${key}']`
              }
            },
            state
          )
          delete clonedOptions.customInputData[key]
        }
      } else if (key === 'profileOptOut') {
        if (!licit.isABoolean(clonedOptions.customInputData[key])) {
          console.warn(
            `[MC SDK] > value for dpaTransactionOptions.customInputData.${key} must be a boolean:`
          )
          remoteLogger.warn(
            {
              ...events.warnings.INVALID_CUSTOM_INPUT_DATA,
              details: {
                message: `src-sdk received an invalid value for dpaTransactionOptions.customInputData.${key}`
              }
            },
            state
          )
          delete clonedOptions.customInputData[key]
        }
      } else {
        console.info(
          `[MC SDK] > Removing invalid property for dpaTransactionOptions.customInputData - ${key}`
        )
        delete clonedOptions.customInputData[key]
      }
    })
    response = clonedOptions
  } catch (e) {
    // The responsibility of logging invalid customInputData is delegated
    // to the licit validations
  }

  return response
}
